import React from "react"
import { Link } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import mascot from '../images/mascot.png'
import Slider from 'react-slick'

var settings = {
  dots: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  infinite: false,
  arrows: false,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

const IndexPage = () => (
  <Layout>
      <SEO title="Home" />
  <div className="jumbotron homepage">
    <div className="container">
      <h1>San Diego's Best Authentic Mexican &amp; Vegan Cuisine</h1>
    </div>
  </div>
  <div className="mascot-bg">
      <div className="food-with-love-container">
        <div className="container">
        <div className="row">
          <div className="col-md-3 food-with-love">
            <h2>Food with love.</h2>
            <p className="instagram-callout d-none d-md-block">Follow us on Instagram for secret menu items!</p>
            <a className="btn btn-primary instagram d-none d-md-block" href="https://www.instagram.com/pokezmexicanrestaurant/?hl=en"><span>Open Instagram</span></a>
          </div>
          <div className="col-md-9">
            <div className="card-group">
                <Link to="menu">
                  <div className="card menu">
                    <div className="card-body">
                        <h4 className="card-title">Menu</h4>
                    </div>
                  </div>
                </Link>
                <Link to="about">
                  <div className="card about-us">
                    <div className="card-body">
                        <h4 className="card-title">About Us</h4>
                    </div>
                  </div>
                </Link>
                <Link to="contact">
                  <div className="card contact-us">
                    <div className="card-body">
                        <h4 className="card-title">Contact</h4>
                    </div>
                  </div>
                </Link>
                <div className="food-with-love">
                  <p className="instagram-callout d-block d-md-none">Follow us on Instagram for secret menu items!</p>
                  <a className="btn btn-primary instagram d-block d-md-none" href="https://www.instagram.com/pokezmexicanrestaurant/?hl=en"><span>Open Instagram</span></a>
                </div>
            </div>
            <div className="container let-us-cook">
              <div className="row">
                <div className="col-md-4">
                    <h2>Come by and let us cook your meal!</h2>
                </div>
                <div className="col-md-8">
                    <p>We offer both low and high octane beverages for your consumption: from house-made sangria, beer, and soda to freshly juiced combinations of fruits and veggies. We recently debuted an espresso bar as well since we serve breakfast all day.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-md-12 get-it-delivered d-block d-md-none">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h2>Get it Delivered!</h2>
          </div>
          <div className="col-md-6 delivery">
            <a className="btn btn-primary postmates" href="https://postmates.com/merchant/pokez-mexican-restaurant-san-diego" target="_blank" rel="noreferrer">Get Delivery</a><a className="btn btn-primary uber" href="https://www.ubereats.com/san-diego/food-delivery/pokez-mexican-restaurant/Xlt0Xj7ZSYK9kWa67tzyeQ" target="_blank">Uber Eats</a>
          </div>
        </div>
      </div>
    </div>
    <img src={mascot} className="mascot-image" alt="mascot"/>
    <div className="green-bg">
      <div className="container d-none d-md-block">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-9 get-it-delivered">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h2>Get it Delivered!</h2>
                </div>
                <div className="col-md-6 delivery">
                  <a className="btn btn-primary postmates" href="https://postmates.com/merchant/pokez-mexican-restaurant-san-diego" target="_blank" rel="noreferrer">Get Delivery</a><a className="btn btn-primary uber" href="https://www.ubereats.com/san-diego/food-delivery/pokez-mexican-restaurant/Xlt0Xj7ZSYK9kWa67tzyeQ" target="_blank">Uber Eats</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2>We are proud of our awards!</h2>
          <Slider {...settings} className="awards">
            <div className="award-list"><span className="award-title">San Diego Magazine – Best Restaurants - Reader’s Choice</span> <span className="award-text">Best Vegetarian</span> <span className="award-year">2006</span></div>
            <div className="award-list"><span className="award-title">Vegan in San Diego</span> <span className="award-text">Best Vegan Burrito</span> <span className="award-year">2017</span></div>
            <div className="award-list"><span className="award-title">Vegan in San Diego</span> <span className="award-text">Best Vegan-Friendly Restaurant</span> <span className="award-year">2017</span></div>
            <div className="award-list"><span className="award-title">Best of Downtown – Gold Medal</span> <span className="award-text">Best Vegan</span> <span className="award-year">2019</span></div>
            <div className="award-list"><span className="award-title">Best of Downtown – Gold Medal</span> <span className="award-text">Best Lunch</span> <span className="award-year">2019</span></div>
            <div className="award-list"><span className="award-title">Best of Downtown – Gold Medal</span> <span className="award-text">Best Mexican Cuisine</span> <span className="award-year">2019</span></div>
            <div className="award-list"><span className="award-title">Best of Downtown – Gold Medal</span> <span className="award-text">Best Burrito</span> <span className="award-year">2019</span></div>
          </Slider>
        </div>
      </div>
    </div>
  </div>
  </Layout>
)

export default IndexPage
